import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, TextField, Box, Typography } from '@material-ui/core';
import { useDebounce } from 'use-debounce';
import * as _ from 'lodash';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import CustomGrid from '../../components/custom-grid/custom-grid';
import CustomGridItem from '../../components/custom-grid/custom-grid-item';
import axios from 'axios';
import AnimatedCard from '../../components/card-image-title-description/animated-card';

const SpeakersPage = ({ pageContext: { _sortedSpeakerByYearArray, _year, _speakerYearsArray } }) => {
  const currentYear = _year;
  const [years, setYears] = useState(_speakerYearsArray.sort((a, b) => b - a));
  const [hasResult, setHasResult] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredSpeaker, setFilteredSpeaker] = useState();
  const [value] = useDebounce(searchText, 1000);
  const [speakers, setSpeakers] = useState(_sortedSpeakerByYearArray);

  useEffect(() => {
    if (value.length > 0) {
      let _filteredSpeaker = [];

      speakers.map((_speaker) => {
        if (
          _speaker.firstName.toLowerCase().includes(value.toLocaleLowerCase()) ||
          (_speaker.salutation &&
            _speaker.salutation.name &&
            _speaker.salutation.name.toLowerCase().includes(value.toLocaleLowerCase())) ||
          _speaker.lastName.toLowerCase().includes(value.toLocaleLowerCase()) ||
          _speaker.organization.toLowerCase().includes(value.toLocaleLowerCase()) ||
          _speaker.designation.toLowerCase().includes(value.toLocaleLowerCase())
        ) {
          _filteredSpeaker.push(_speaker);
        }
      });

      if (_filteredSpeaker.length > 0) {
        setHasResult(true);
        setFilteredSpeaker(_filteredSpeaker);
      } else {
        setHasResult(false);
      }
    } else {
      setHasResult(true);
      setFilteredSpeaker(speakers);
    }

    return () => {};
  }, [value]);

  return (
    <Layout>
      <SEO lang='en' title={`Speakers | Global Manufacturing & Industrialisation Summit | GMIS${currentYear}`} />
      <SectionContainerLayoutWithFilter
        title={`SPEAKERS ${currentYear}`}
        baseLink='/speakers'
        filters={years}
        isViewAll
        isDivider>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              placeholder='Search Speakers'
              label='Search Speakers'
              color='secondary'
              variant='outlined'
              fullWidth
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            {hasResult ? (
              <CustomGrid>
                {filteredSpeaker.map((_speaker, index) => (
                  <CustomGridItem key={index}>
                    {/* <CardImageTitleDescription
                      isImgTag
                      image={_speaker.file.code}
                      title={
                        _speaker.SalutationId === 7
                          ? _speaker.firstName + ' ' + _speaker.lastName
                          : _speaker.salutation.name + ' ' + _speaker.firstName + ' ' + _speaker.lastName
                      }
                      body={
                        _speaker.organization && _speaker.organization.length > 1
                          ? _speaker.designation + ', ' + _speaker.organization
                          : _speaker.designation
                      }
                    /> */}
                    <AnimatedCard
                      bio={_speaker.bio ? _speaker.bio : null}
                      image={_speaker.file.code}
                      title={
                        _speaker.salutationId === 7 || _speaker.salutation.name === 'NULL'
                          ? _speaker.firstName + ' ' + _speaker.lastName
                          : _speaker.salutation.name + ' ' + _speaker.firstName + ' ' + _speaker.lastName
                      }
                      body={
                        _speaker.organization && _speaker.organization.length > 1
                          ? _speaker.designation + ', ' + _speaker.organization
                          : _speaker.designation
                      }
                    />
                  </CustomGridItem>
                ))}
              </CustomGrid>
            ) : (
              <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                <Typography color='textSecondary' variant='button'>
                  No Speakers Found
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default SpeakersPage;
